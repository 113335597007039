<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <!-- isLoading Spinner -->
        <v-row v-if="isLoading" justify="center">
          <v-col cols="12">
            <div class="ma-4 text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <p>Chargement des domaines...</p>
            </div>
          </v-col>
        </v-row>

        <!-- Error Message -->
        <v-alert v-if="error" type="error" dismissible class="mt-4">
          Erreur lors du chargement des domaines : {{ error }}
        </v-alert>
        <div v-if="!isLoading && !error">
          <h1>Domaine : {{ domaineDetailed.name }}</h1>
          <p>{{ domaineDetailed.description }} :</p>
          <h2>Textes pour le domaine :</h2>
          <v-card class="mt-4">
            <v-list>
              <div v-for="texte in domaineDetailed.texts" :key="texte.id">
                <v-list-item @click="goToTexteDetail(texte.id)">
                  <v-list-item-content>
                    <v-list-item-title>{{ texte.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      texte.date
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </v-list>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TextesListPage",
  props: ["domaine"],
  data() {
    return {
      domaineDetailed: null, //{id: 1,domaineId: 1,title: "",date: "",description:  "",contenu: ""}
      isLoading: true,
      error: null,
    };
  },
  mounted() {
    this.fetchTextes();
  },
  methods: {
    goToTexteDetail(texteId) {
      this.$router.push({ name: "TexteDetailPage", params: { id: texteId } });
    },
    async fetchTextes() {
      this.isLoading = true;
      this.error = null;
      try {
        const response = await fetch(
          "https://veillepro.com/api/domains-detailed/" + this.domaine
        );
        if (!response.ok) {
          throw new Error("Failed to fetch the data.");
        }
        const data = await response.json();
        console.log(data);
        this.domaineDetailed = data.data;
      } catch (error) {
        this.error = error.message || "Une erreur est survenue.";
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
