import { createRouter, createWebHashHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import AboutPage from '@/components/AboutPage.vue';
import ContactUsPage from '@/components/ContactUsPage.vue';
import FAQPage from '@/components/FAQPage.vue';
import DomainesPage from '@/components/DomainesPage.vue';
import TextesListPage from '@/components/TextesListPage.vue';
import TexteDetailPage from '@/components/TexteDetailPage.vue';
import TestimonialsPage from '@/components/TestimonialsPage.vue';

// Define your routes
const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/about',
        name: 'AboutPage',
        component: AboutPage,
    },
    {
        path: '/contact',
        name: 'ContactUsPage',
        component: ContactUsPage
    },
    {
        path: '/faq',
        name: 'FAQPage',
        component: FAQPage
    },
    {
        path: '/testimonials',
        name: 'TestimonialsPage',
        component: TestimonialsPage
    },
    {
        path: '/domaines',
        name: 'DomainesPage',
        component: DomainesPage
    },
    {
      path: '/domaines/:domaine',
      name: 'TextesListPage',
      component: TextesListPage,
      props: true, // to pass route params as props
    },
    {
      path: '/textes/:id',
      name: 'TexteDetailPage',
      component: TexteDetailPage,
      props: true, // to pass route params as props
    },
    
];

// Create a router instance
const router = createRouter({
    history: createWebHashHistory(),
    //history: createWebHistory(),
    routes,
});

export default router;