<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <h1>Témoignages</h1>
        <v-carousel cycle hide-delimiters>
          <v-carousel-item
            v-for="(testimonial, index) in testimonials"
            :key="index"
          >
            <v-card class="mx-auto pa-5" max-width="500">
              <v-avatar class="mx-auto" size="120">
                <img :src="testimonial.image" alt="user avatar" />
              </v-avatar>
              <v-card-title class="text-h6">{{
                testimonial.name
              }}</v-card-title>
              <v-card-subtitle>{{ testimonial.position }}</v-card-subtitle>
              <v-card-text class="text-center">
                "{{ testimonial.feedback }}"
              </v-card-text>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TestimonialsPage",
  data() {
    return {
      testimonials: [
        {
          name: "Karim B.",
          position: "Directeur Général chez ABC Entreprise",
          feedback:
            "VeillePro nous a aidés à rester conformes aux nouvelles réglementations, ce qui a considérablement réduit notre risque juridique.",
          image: "https://via.placeholder.com/120", // Placeholder image
        },
        {
          name: "Sofia M.",
          position: "Responsable Conformité chez XYZ Industries",
          feedback:
            "Grâce à VeillePro, nous avons pu automatiser la veille réglementaire et nous concentrer sur notre cœur de métier.",
          image: "https://via.placeholder.com/120", // Placeholder image
        },
        {
          name: "Amine R.",
          position: "CEO chez InnovTech",
          feedback:
            "La plateforme est facile à utiliser et les alertes personnalisées nous permettent de ne jamais manquer de changements législatifs importants.",
          image: "https://via.placeholder.com/120", // Placeholder image
        },
      ],
    };
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 20px;
  font-weight: bold;
}
.v-card {
  text-align: center;
}
.v-avatar {
  margin-bottom: 20px;
}
</style>
