<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <h1>Foire aux Questions (FAQ)</h1>
        <v-expansion-panels>
          <v-expansion-panel v-for="(faq, index) in faqs" :key="index" :title="faq.question" :text="faq.answer">
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FAQPage',
  data() {
    return {
      faqs: [
        {
          question: 'Comment puis-je m\'inscrire sur VeillePro ?',
          answer:
            'Pour vous inscrire, cliquez sur le bouton "S\'inscrire" en haut à droite de la page d\'accueil et suivez les instructions.',
        },
        {
          question: 'Quels sont les services offerts par VeillePro ?',
          answer:
            'VeillePro offre une surveillance réglementaire en temps réel, l\'évaluation de la conformité, et des alertes personnalisées pour aider les entreprises à rester informées des évolutions légales.',
        },
        {
          question: 'Comment puis-je contacter le support technique ?',
          answer:
            'Vous pouvez contacter notre équipe de support technique via notre formulaire de contact ou par email à support@veillepro.dz.',
        },
      ],
    };
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 20px;
  font-weight: bold;
}
</style>
