<template>
  <v-container>
    <v-row>
      <!-- md="8" offset-md="2" -->
      <v-col cols="12">
        <h1>Bienvenue sur VeillePro</h1>
        <p>
          VeillePro est votre solution complète pour rester informé des
          dernières évolutions législatives, réglementaires et normatives en
          Algérie. Nous vous aidons à anticiper les changements et à garantir la
          conformité de votre entreprise avec les lois en vigueur.
        </p>

        <br />
        <v-divider></v-divider>

        <v-row v-if="isLoading" justify="center">
          <v-col cols="12">
            <div class="ma-4 text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <p>Chargement des domaines...</p>
            </div>
          </v-col>
        </v-row>

        <!-- Error Message -->
        <v-alert v-if="error" type="error" dismissible class="mt-4">
          Erreur lors du chargement des domaines : {{ error }}
        </v-alert>

        <div v-if="!isLoading && !error">
          <h2>Domaines couverts</h2>
          <p>
            VeillePro surveille en temps réel les évolutions réglementaires dans
            les domaines suivants :
          </p>
          <br>
          <v-row v-if="!isLoading && !error">
            <v-col
              v-for="domaine in domaines"
              :key="domaine.name"
              cols="12"
              md="3"
            >
              <v-card @click="goToTextesList(domaine.id)">
                <v-responsive :aspect-ratio="16 / 9">
                  <v-img
                    :src="domaine.image"
                    alt="Image"
                    class="rounded-t"
                  ></v-img>
                </v-responsive>
                <v-card-title>{{ domaine.name }}</v-card-title>
                <v-card-text>
                  {{ truncateWords(domaine.description) }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <br />
        <v-divider></v-divider>

        <h2>Pourquoi choisir VeillePro ?</h2>
        <p>
          En vous abonnant à VeillePro, vous bénéficierez d'une plateforme
          intuitive, des alertes personnalisées et d'outils de gestion des
          risques pour une conformité simplifiée.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DomainePage",
  data() {
    return {
      domaines: [], //{ id: 1, name: '', description: '',image: '', }
      isLoading: true,
      error: null,
    };
  },
  mounted() {
    this.fetchDomaines();
  },
  methods: {
    truncateWords(value) {
      if (!value) return "";
      const words = value.split(" ");
      return words.slice(0, 10).join(" ") + (words.length > 10 ? "..." : "");
    },
    goToTextesList(domaineId) {
      this.$router.push({
        name: "TextesListPage",
        params: { domaine: domaineId },
      });
    },
    async fetchDomaines() {
      this.isLoading = true;
      this.error = null;
      try {
        const response = await fetch("https://veillepro.com/api/domains");
        if (!response.ok) {
          throw new Error("Failed to fetch the data.");
        }
        const data = await response.json();
        console.log(data);
        this.domaines = data.data;
      } catch (error) {
        this.error = error.message || "Une erreur est survenue.";
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 20px;
  font-weight: bold;
}
h2 {
  margin-top: 40px;
}
p {
  font-size: 16px;
  line-height: 1.6;
}
v-divider {
  margin: 20px 0;
}
</style>
