// Function to excerpt a text by a word limit
export function excerptTextByWords(text, wordLimit) {
    if (!text) return "";
    const words = text.split(" ");
    if (words.length <= wordLimit) {
        return text; // Return the whole text if it's shorter than the limit
    }
    return words.slice(0, wordLimit).join(" ") + "..."; // Excerpt and add ellipsis
}


// Function to excerpt a text by a word limit
export function textToHtmlTable(text) {
    // Split the text into sections based on line breaks or article indicators like 'Article' or 'Art.'
    const lines = text.split(/\r?\n/).filter(line => line.trim() !== "");
    let html = `
      <table class="text-html-table">
        <thead class="table-light">
          <tr>
            <th>Exigence</th>
            <th>Applicable?</th>
            <th>Conforme?</th>
            <th>Observation</th>
          </tr>
        </thead>
        <tbody>
    `;
  
    lines.forEach((line, index) => {
      // Add rows for each line of text
      html += `
        <tr>
          <td class="exigence">${line}</td>
          <td class="applicable"><input type="checkbox" name="applicable${index}" /></td>
          <td class="compliant"><input type="checkbox" name="compliant${index}" /></td>
          <td class="observation"><input type="text" name="observation${index}" placeholder="" /></td>
        </tr>
      `;
    });
  
    html += `
        </tbody>
      </table>
    `;
  
    return html;
}

