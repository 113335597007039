<template>
  <v-container class="home-container" fluid>
    <!-- Welcome Section -->
    <v-row justify="center" class="welcome-section">
      <v-col cols="12" md="8" class="text-center">
        <h1 class="display-1">Bienvenue sur VeillePro</h1>
        <p class="slogan">Restez informé. Restez conforme.</p>
        <p class="subtitle text-weight-700">
          Votre solution pour la veille réglementaire et l'évaluation de
          conformité en Algérie.
        </p>
        <v-row justify="center">
          <v-img
            src="@/assets/logo.png"
            alt="VeillePro Logo"
            max-width="200"
            class="center-logo"
          ></v-img>
        </v-row>
        <br />
        <router-link to="/domaines" class="mt-5">
          <v-btn color="primary" large>Commencer Maintenant</v-btn>
        </router-link>
      </v-col>
    </v-row>

    <!-- Features Section -->
    <v-row class="features-section" justify="center">
      <v-col cols="12" md="4">
        <v-card class="mx-auto">
          <v-img
            src="@/assets/caracteristique-veille-reglementaire.jpg"
            alt="VeillePro - Caractéristique - Veille Réglementaire"
            class="rounded-t"
            height="200px"
            cover
          ></v-img>
          <v-card-title>Veille Réglementaire</v-card-title>
          <v-card-text>
            Suivez en temps réel les évolutions législatives et réglementaires
            pertinentes pour votre secteur d'activité.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="mx-auto">
          <v-img
            src="@/assets/caracteristique-gestion-conformite.jpg"
            alt="VeillePro - Caractéristique - Gestion de Conformité"
            class="rounded-t"
            height="200px"
            cover
          ></v-img>
          <v-card-title>Gestion de Conformité</v-card-title>
          <v-card-text>
            Anticipez les changements réglementaires et évitez les risques de
            non-conformité grâce à nos outils d'évaluation.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="mx-auto">
          <v-img
            src="@/assets/caracteristique-rapports-personnalises.jpg"
            alt="VeillePro - Caractéristique - Rapports Personnalisés"
            class="rounded-t"
            height="200px"
            cover
          ></v-img>
          <v-card-title>Rapports Personnalisés</v-card-title>
          <v-card-text>
            Recevez des rapports détaillés et personnalisés pour garantir votre
            conformité aux exigences locales.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomePage",
};
</script>

<style scoped>
.home-container {
  padding: 40px 20px;
}

.welcome-section {
  margin-bottom: 30px;
}

.display-1 {
  font-weight: bold;
}

.subtitle {
  font-size: 18px;
  color: #424242;
}
.slogan {
  font-size: 24px;
  color: #0909af;
}

.center-logo {
  display: block;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.features-section {
  margin-top: 10px;
}

.v-btn {
  font-size: 18px;
  font-weight: 500;
}
</style>
