<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <h1>Contactez-nous</h1>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="name"
            label="Nom"
            :rules="[rules.required]"
            required
          ></v-text-field>

          <v-text-field
            v-model="email"
            label="Email"
            :rules="[rules.required, rules.email]"
            required
          ></v-text-field>

          <v-text-field
            v-model="subject"
            label="Sujet"
            :rules="[rules.required]"
            required
          ></v-text-field>

          <v-textarea
            v-model="message"
            label="Message"
            :rules="[rules.required]"
            required
          ></v-textarea>

          <v-btn :disabled="!valid" color="primary" @click="submitForm">
            Envoyer
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "ContactUsForm",
  data() {
    return {
      valid: false,
      name: "",
      email: "",
      subject: "",
      message: "",
      rules: {
        required: (value) => !!value || "Ce champ est requis.",
        email: (value) => {
          const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return pattern.test(value) || "Adresse e-mail invalide.";
        },
      },
    };
  },
  methods: {
    async submitForm() {
      if (this.$refs.form.validate()) {
        try {
          const response = await axios.post(
            "http://127.0.0.1:8000/api/contact",
            {
              name: this.name,
              email: this.email,
              subject: this.subject,
              message: this.message,
            }
          );

          alert(response.data.message);
          this.resetForm();
        } catch (error) {
          if (error.response && error.response.status === 422) {
            alert(
              "Une erreur s'est produite : " +
                JSON.stringify(error.response.data.errors)
            );
          } else {
            alert("Une erreur s'est produite, veuillez réessayer plus tard.");
          }
        }
      }
    },
    resetForm() {
      this.name = "";
      this.email = "";
      this.subject = "";
      this.message = "";
      this.valid = false;
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 20px;
  font-weight: bold;
}
</style>
