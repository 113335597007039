<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img src="@/assets/logo.png" alt="App Logo" max-width="50" class="mr-3"></v-img>
      <v-app-bar-title>VeillePro</v-app-bar-title>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app>
      <v-list dense>
        <v-list-item-group>
          <v-list-item to="/">
            <v-list-item-content>
              <v-list-item-title>Accueil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/domaines">
            <v-list-item-content>
              <v-list-item-title>Domaines</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/contact">
            <v-list-item-content>
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/about">
            <v-list-item-content>
              <v-list-item-title>À Propos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view /> <!-- The router view where components will be rendered -->
    </v-main>

    <v-footer app color="primary" dark absolute>
      <span class="white--text">&copy; 2024 My Vue App</span>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      drawer: true, // This controls the drawer visibility
    };
  },
};
</script>