<template>
  <v-container>
    <v-row>
      <!-- md="8" offset-md="2" -->
      <v-col cols="12"> 
        
        <!-- isLoading Spinner -->
        <v-row v-if="isLoading" justify="center">
          <v-col cols="12">
            <div class="ma-4 text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <p>Chargement des domaines...</p>
            </div>
          </v-col>
        </v-row>

        <!-- Error Message -->
        <v-alert v-if="error" type="error" dismissible class="mt-4">
          Erreur lors du chargement des domaines : {{ error }}
        </v-alert>

        <!-- Domaines List -->
        <v-card v-if="!isLoading && !error">
          <v-card-title>
            {{ texteDetailed.title }}
          </v-card-title>
          <v-card-subtitle>
            Date de publication : {{ texteDetailed.date_publication }}
          </v-card-subtitle>

          <v-card-text>
            <p>{{ texteDetailed.type }}</p>
            <p><strong>Contenu :</strong></p>
            <div v-html="excerptContent"></div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text>
            <div v-html="textToHtmlTable"></div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text v-if="texteDetailed.pdf">
            <div>
              <h3>Preview:</h3>
              <div>{{ texteDetailed.pdf }}</div>
              <div>
                <embed
                  :src="texteDetailed.pdf"
                  type="application/pdf"
                  width="100%"
                  height="500"
                />
              </div>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              v-if="texteDetailed.pdf"
              color="deep-blue"
              variant="outlined"
              class="mr-4"
              @click="openPDF()"
            >
              Téléchargeur
            </v-btn>
            <v-btn
              color="deep-purple"
              variant="outlined"
              :href="texteDetailed.pdf"
            >
              Retour à la liste des textes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { excerptTextByWords, textToHtmlTable } from "@/utils/textUtils";

export default {
  name: "TexteDetailPage",
  props: ["id"],
  data() {
    return {
      texteDetailed: null,
      isLoading: true,
      error: null,
    };
  },
  computed: {
    formattedContent() {
      return this.texteDetailed && this.texteDetailed.content
        ? this.texteDetailed.content.replace(/\n/g, "<br>")
        : "";
    },
    excerptContent() {
      return excerptTextByWords(this.formattedContent, 300);
    },
    textToHtmlTable() {
      return textToHtmlTable(this.texteDetailed.content, 300);
    },
  },
  mounted() {
    this.fetchTexteDetails();
  },
  methods: {
    async fetchTexteDetails() {
      this.isLoading = true;
      this.error = null;
      try {
        const response = await fetch(
          "https://veillepro.com/api/texts-detailed/" + this.id
        );
        if (!response.ok) {
          throw new Error("Failed to fetch the data.");
        }
        const data = await response.json();
        console.log(data);
        this.texteDetailed = data.data;
      } catch (error) {
        this.error = error.message || "Une erreur est survenue.";
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 20px;
  font-weight: bold;
}
p {
  font-size: 16px;
  line-height: 1.6;
}
v-divider {
  margin: 20px 0;
}
</style>
