<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <h1>À Propos de VeillePro</h1>
        <p>
          VeillePro est une plateforme spécialisée dans la veille réglementaire
          et l'évaluation de conformité en Algérie. Nous aidons les entreprises
          à rester constamment informées des évolutions législatives,
          réglementaires et normatives pertinentes pour leur secteur d'activité.
          Grâce à une surveillance en temps réel, nous permettons aux
          entreprises d'anticiper les changements, de mieux gérer les risques de
          non-conformité, et de garantir leur respect des exigences
          réglementaires en vigueur.
        </p>
        <v-divider></v-divider>
        <h2>Notre Mission</h2>
        <p>
          Notre mission est de simplifier la veille réglementaire pour les
          entreprises algériennes, en leur fournissant des informations à jour
          et pertinentes, afin de leur permettre de se concentrer sur leur
          activité principale tout en restant conformes aux législations en
          constante évolution.
        </p>
        <v-divider></v-divider>
        <h2>Notre Équipe</h2>
        <p>
          L'équipe de VeillePro est composée d'experts en conformité
          réglementaire, juridiques et techniques, passionnés par la création de
          solutions innovantes pour répondre aux défis réglementaires des
          entreprises. Ensemble, nous travaillons à fournir un service de haute
          qualité qui fait de la conformité une priorité facile à gérer.
        </p>
        <v-divider></v-divider>
        <h2>Liens Utiles : </h2>
        <ul class="ms-6">
          <li><RouterLink  to="/faq">FAQ</RouterLink></li>
          <li><RouterLink  to="/testimonials">Témoignages</RouterLink></li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import TestimonialsPage from './TestimonialsPage.vue';
export default {
  name: "AboutPage",
  components: {
    //TestimonialsPage,
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 20px;
  font-weight: bold;
}
h2 {
  margin-top: 40px;
  font-weight: bold;
}
p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}
</style>
